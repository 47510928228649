import { Row, Col, Card, Typography, Popover, Button, Space } from "@pankod/refine-antd";
import { useTranslation } from "react-i18next";
import { authProvider } from "../../authProvider";
import { usePermissions } from "@pankod/refine-core";

const { Text, Title } = Typography;

export const DashboardPage: React.FC = () => {
    const { t } = useTranslation();

    const szlLetrehoz = (
        <div>
          <h2>Hogyan tudok szállítólevelet létrehozni?</h2>
          <p>Szállítólevelet a Szállítólevelek menüpontban a Létrehozás gombra kattintva tudunk létrehozni.</p>
        </div>
    );

    const szlSzerkeszt = (
        <div>
          <h2>Hogyan tudok szállítólevelet szerkeszteni?</h2>
          <p>Szállítólevelet a Szállítólevelek menüpontban a szerkeszteni kívánt szállítólevél Műveletek oszlopában található Szerkesztés gombra kattintva.</p>
          <p>Fontos, hogy ha a szállítólevélhez tartozó terméket szeretnénk módosítani, akkor a meglévőt töröljük, majd adjunk hozzá az újat és így mentsük el.</p>
        </div>
    );

    const szlExp = (
        <div>
          <h2>Hogyan tudok szállítólevelet exportálni?</h2>
          <p>Szállítóleveleket az Exportálás menüpontban tudunk exportálni. Ott csak a már engedélyezett szállítólevelek jelennek meg.</p>
          <p>A kiválasztott szállítólevelek után nyomjuk meg az Export gombot a táblázat jobb felső sarkában.</p>
          <p>Ha esetleg olyan szállítólevelet szeretnénk újra exportálni, amit egyszer már exportáltunk, akkor a szűrési feltételeknél pipáljuk be a szűrést már exportált szállítólevelekre.</p>
        </div>
    );

    return (
        <Row gutter={[16, 16]}>
            <Col md={24}>
                <Row gutter={[16, 16]}>
                    <Col xl={10} lg={24} md={24} sm={24} xs={24}>
                        <Card
                            bodyStyle={{
                                padding: 10,
                                paddingBottom: 0,
                            }}
                            style={{
                                background: "",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right"
                            }}
                        >
                            <Title level={3}>Üdvözöljük a főoldalon!</Title>
                            <Title level={5}>Új termék hozzáadásához válassza a "Termék törzs" opciót.</Title>
                            <Title level={5}>A létrehozott szállítóleveleket a "Szállítólevél" opciónál kezelheti.</Title>

                        </Card>
                        <br></br>
                            <Title level={4}>Gyakori kérdések</Title>
                            
                            <Space>
                                <Popover placement="rightBottom" content={szlLetrehoz} title="Gyakori kérdések" trigger="click">
                                    <Button type="primary">Szállítólevél létrehozása</Button>
                                </Popover>

                                <Popover placement="rightBottom" content={szlSzerkeszt} title="Gyakori kérdések" trigger="click">
                                    <Button type="primary">Szállítólevél szerkesztése</Button>
                                </Popover>

                                <Popover content={szlExp} title="Gyakori kérdések" trigger="click">
                                    <Button type="primary">Szállítólevél exportálása</Button>
                                </Popover>
                            </Space>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};