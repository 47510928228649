import {
  Show,
  Typography,
  Col,
  Row,
  Button,
  List,
  useTable,
  DateField,
  NumberField,
  TextField,
} from "@pankod/refine-antd";
import { useOne, useShow } from "@pankod/refine-core";
import React from "react";
//import ReactToPrint from 'react-to-print';
import { useReactToPrint } from "react-to-print";
import { IBizonylatFej, IBizonylatTetelek, ITermek } from "../../interfaces";
import { Table, Tag, Space } from "antd";
import "./style.css";
import moment from "moment";

const { Title, Text } = Typography;
//const showRef = React.createRef();

export const SzallitasShow = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;
  const record = data?.data;
  const componentRef = React.useRef<HTMLDivElement>(null);
  const componentRef2 = React.useRef<HTMLDivElement>(null);

  const marginTop = "30px";
  const marginRight = "50px";
  const marginBottom = "50px";
  const marginLeft = "50px";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
  };

  const handlePrint = useReactToPrint({
    content: () =>
      componentRef && componentRef?.current ? componentRef.current : null,
  });

  const handlePrint2 = useReactToPrint({
    content: () =>
      componentRef2 && componentRef2?.current ? componentRef2.current : null,
  });

  const mystyle = {
    padding: "10px",
  };

  const { tableProps: tabelPropsBiz } = useTable<IBizonylatFej>();

  const columns2 = [
    {
      title: "Megnevezés",
      dataIndex: "termek.azonosito1",
      key: "termek.azonosito1",
      render: (text: any, data: any) => data?.termek.azonosito1,
      //JSON.stringify(record)//
    },
    {
      title: "SAP anyagszám",
      dataIndex: "termek.azonosito2",
      key: "termek.azonosito2",
      render: (text: any, data: any) => data?.termek.azonosito2,
      //JSON.stringify(record)//
    },
    {
      title: "Lieferplan szám",
      dataIndex: "termek.azonosito3",
      key: "termek.azonosito3",
      render: (text: any, data: any) => data?.termek.azonosito3,
      //JSON.stringify(record)//
    },
  ];

  const columns = [
    {
      title: "Megnevezés",
      dataIndex: "termek.azonosito1",
      key: "termek.azonosito1",
      render: (text: any, data: any) => data?.termek.azonosito1,
      //JSON.stringify(record)//
    },
    {
      title: "SAP anyagszám",
      dataIndex: "termek.azonosito2",
      key: "termek.azonosito2",
      render: (text: any, data: any) => data?.termek.azonosito2,
      //JSON.stringify(record)//
    },
    {
      title: "Lieferplan szám",
      dataIndex: "termek.azonosito3",
      key: "termek.azonosito3",
      render: (text: any, data: any) => data?.termek.azonosito3,
      //JSON.stringify(record)//
    },
    {
      title: "ECV Index",
      dataIndex: "termek.elsoIndex",
      key: "termek.elsoIndex",
      render: (text: any, data: any) => data?.termek.elsoIndex,
      //JSON.stringify(record)//
    },
    {
      title: "EDP Index",
      dataIndex: "termek.masodikIndex",
      key: "termek.masodikIndex",
      render: (text: any, data: any) => data?.termek.masodikIndex,
      //JSON.stringify(record)//
    },
    {
      title: "Mennyiség",
      dataIndex: "mennyisege",
      key: "mennyisege",
      render: (text: any, data: any) => (
        <>
          <NumberField
            value={data?.mennyisege}
            options={{
              notation: "standard",
            }}
          />{" "}
          {data?.termek.mennyEgyseg}
        </>
      ),
      //JSON.stringify(record)//
    },
  ];

  return (
    <>
      <Show canEdit={false} isLoading={isLoading} title="Szállítólevél adatai">
        <div ref={componentRef}>
          <table className="report-container">
            <tfoot className="report-footer1">
              <tr>
                <td className="report-footer-cell">
                  <div className="footer-info">
                    <div className={"page-footer"}>
                      Nyomtatás dátuma:{" "}
                      <DateField
                        format=""
                        value={moment().format()}
                      ></DateField>
                      <br></br>
                      <br></br>
                    </div>
                    <div className={"page-footer2"}>
                      <Row gutter={20}>
                        <Col xs={24} lg={16}>
                          <Row gutter={20}>
                            <Col xs={12} lg={8}>
                              <Title level={5}>Kiállító :</Title>
                            </Col>

                            <Col xs={12} lg={8}>
                              <Title level={5}>Átvevő:</Title>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>

            <style>{getPageMargins()}</style>
            <Col xs={24}>
              <Row gutter={20} style={{ paddingBottom: "3rem" }}>
                <Col xs={12}>
                  <Title level={3}>Szállítólevél</Title>
                </Col>
                <Col xs={12} >
                  <Title level={5}>Szállítólevél száma</Title>
                  <Text>{record?.bizonylatSzam}</Text>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col xs={12}>
                  <Row gutter={[16, 24]}>
                    <Col xs={24}>
                      <Title level={5}>Szállító neve</Title>
                      <Text>{record?.szallitoNeve}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Szállító címe</Title>
                      <Text>{record?.szallitoCime}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Szállító adószáma</Title>
                      <Text>{record?.szallitoAdoszama}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Szállító EU adószáma</Title>
                      <Text>{record?.szallitoEuAdoszama}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Járatszám</Title>
                      <Text>{record?.jaratSzam}</Text>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12}>
                  <Row gutter={[16, 24]}>
                    <Col xs={24}>
                      <Title level={5}>Vevő neve</Title>
                      <Text>{record?.vevoNeve}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Vevő címe</Title>
                      <Text>{record?.vevoCime}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Vevő adószáma</Title>
                      <Text>{record?.vevoAdoszama}</Text>
                    </Col>
                    <Col xs={24}>
                      <Title level={5}>Vevő EU adószáma</Title>
                      <Text>{record?.vevoEuAdoszama}</Text>
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[16, 24]}>
                        <Col xs={12}>
                          <Title level={5}>Dátum</Title>
                          <DateField
                            format="YYYY/MM/DD"
                            value={record?.datum}
                          ></DateField>
                        </Col>
                        <Col xs={12}>
                          <Title level={5}>MASQ</Title>
                          <Text>{record?.projectId}</Text>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <br></br>
            <Table
              pagination={false}
              columns={columns}
              rowKey="id"
              dataSource={record?.tetelek}
            />
          </table>
        </div>
        <div>
          <tfoot className="report-footer1">
            <td className="report-footer-cell">
              <div className="footer-info">
                <div className={"page-footer3"} ref={componentRef2}>
                  <Title level={1}>Anyagszám</Title>
                  <Text style={{ fontSize: 30 }}>
                    {record?.tetelek[0]?.termek?.azonosito1}
                  </Text>

                  <Title level={1}>SAP</Title>
                  <Text style={{ fontSize: 30 }}>
                    {record?.tetelek[0]?.termek?.azonosito2}
                  </Text>

                  <Title level={1}>Rendelésszám</Title>
                  <Text style={{ fontSize: 30 }}>
                    {record?.tetelek[0]?.termek?.azonosito3}
                  </Text>
                </div>
              </div>
            </td>
          </tfoot>

          <br></br>
          <Space>
            <button onClick={handlePrint}>Nyomtatás</button>
            <button onClick={handlePrint2}>Címkék nyomtatása</button>
          </Space>
          {/*    <ReactToPrint
        trigger={()=>{
            return <button>Nyomtatás</button>
        }} content={() => componentRef}
        />
    */}
        </div>
      </Show>
    </>
  );
};

/*
<Table {...tabePropsBiz} rowKey="id">
                <Table.Column dataIndex="bizonylatSzam" title="Bizonylatszám" />
                    <Table.Column<IBizonylatFej>
                        dataIndex="datum"
                        title="Dátum"
                        render={(value) => (
                            <DateField format="LLL" value={value} />
                        )}
                    />
                    
                </Table>
*/
