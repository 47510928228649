import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  Create,
  Form,
  Row,
  Col,
  Input,
  useSelect,
  useForm,
  AntdLayout,
  AutoComplete,
  EditButton,
  Icons,
  Typography,
  useDrawerForm,
  Drawer,
  Edit,
} from "@pankod/refine-antd";
import { useList, usePermissions } from "@pankod/refine-core";
import routerProvider from "@pankod/refine-react-router-v6";
import { useEffect, useState } from "react";

import { IOptions, ITermek } from "../../interfaces";

export const TermekList: React.FC = () => {
  const { tableProps } = useTable<ITermek>({
    permanentSorter: [
      {
        field: "azonosito1",
        order: "asc",
      },
      {
        field: "azonosito2",
        order: "asc",
      },
      {
        field: "azonosito3",
        order: "asc",
      },
    ],
  });
  const { formProps, saveButtonProps } = useForm<ITermek>();
  const { selectProps } = useSelect<ITermek>({
    resource: "termeks",
    optionLabel: "azonosito1",
    optionValue: "azonosito1",
  });

  const { SearchOutlined } = Icons;
  const { Link } = routerProvider;
  const { Text } = Typography;

  // To be able to customize the option title
  const renderTitle = (title: string) => {
    return (
      <Text strong style={{ fontSize: "16px" }}>
        {title}
      </Text>
    );
  };

  // To be able to customize the option item
  const renderItem = (title: string, resource: string, id: string) => {
    return {
      value: title,
      label: <Text>{title}</Text>,
    };
  };
  const [value, setValue] = useState<string>("");
  const [options, setOptions] = useState<IOptions[]>([]);

  const { refetch: refetchTermekek } = useList<ITermek>({
    resource: "termeks",
    config: {
      filters: [{ field: "azonosito", operator: "eq", value }],
    },
    queryOptions: {
      enabled: false,
      onSuccess: (data) => {
        const termekOptionGroup = data.data.map((item) =>
          renderItem(
            `${item.azonosito1} - ${item.azonosito2} - ${item.azonosito3}`,
            "termeks",
            item.id ?? ""
          )
        );
        if (termekOptionGroup.length > 0) {
          setOptions((prevOptions) => [
            ...prevOptions,
            {
              label: renderTitle("Termékek"),
              options: termekOptionGroup,
            },
          ]);
        }
      },
    },
  });

  useEffect(() => {
    setOptions([]);
    refetchTermekek();
  }, [value]);

  const {
    drawerProps,
    formProps: drawerFormProps,
    show,
    saveButtonProps: drawerSaveButton,
    deleteButtonProps,
    id,
  } = useDrawerForm<ITermek>({
    action: "edit",
  });

  const { data: permissionsData } = usePermissions();

  return (
    <>


      {(permissionsData?.includes("admin")) ? <Create
        title="Termékek hozzáadása a listához"
        saveButtonProps={saveButtonProps}
      >
        <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
          <Row gutter={20}>
            <Col xs={24} lg={30}>
              <Row gutter={10}>
                <Col xs={10} lg={8}>
                  <Form.Item label="Megnevezés" name="azonosito1">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={10} lg={8}>
                  <Form.Item label="SAP anyagszám" name="azonosito2">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={10} lg={8}>
                  <Form.Item label="Lieferplan szám" name="azonosito3">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={10} lg={8}>
                  <Form.Item label="ECV Index	" name="elsoIndex">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={10} lg={8}>
                  <Form.Item label="EDP Index" name="masodikIndex">
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={10} lg={8}>
                  <Form.Item label="Mennyiségi egység" name="mennyEgyseg">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Create>
        : (permissionsData?.includes("superadmin")) ? <Create
          title="Termékek hozzáadása a listához"
          saveButtonProps={saveButtonProps}
        >
          <Form {...formProps} wrapperCol={{ span: 14 }} layout="vertical">
            <Row gutter={20}>
              <Col xs={24} lg={30}>
                <Row gutter={10}>
                  <Col xs={10} lg={8}>
                    <Form.Item label="Megnevezés" name="azonosito1">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={10} lg={8}>
                    <Form.Item label="SAP anyagszám" name="azonosito2">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={10} lg={8}>
                    <Form.Item label="Lieferplan szám" name="azonosito3">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={10} lg={8}>
                    <Form.Item label="ECV Index	" name="elsoIndex">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={10} lg={8}>
                    <Form.Item label="EDP Index" name="masodikIndex">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col xs={10} lg={8}>
                    <Form.Item label="Mennyiségi egység" name="mennyEgyseg">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Create> : ""}
      <AntdLayout.Header
        style={{
          padding: "0px 24px",
          backgroundColor: "#FFF",
        }}
      >
        <AutoComplete
          style={{ width: "100%", maxWidth: "550px" }}
          filterOption={false}
          options={options}
          onSearch={(value: string) => setValue(value)}
        >
          <Input
            size="large"
            placeholder="Termékek keresése"
            suffix={<SearchOutlined />}
          />
        </AutoComplete>
      </AntdLayout.Header>

      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column dataIndex="azonosito1" title="Megnevezés" />
          <Table.Column dataIndex="azonosito2" title="SAP anyagszám" />
          <Table.Column dataIndex="azonosito3" title="Lieferplan szám" />
          <Table.Column dataIndex="elsoIndex" title="ECV Index" />
          <Table.Column dataIndex="masodikIndex" title="EDP Index" />
        </Table>
      </List>
      {/*<Drawer {...drawerProps}>
        <Edit
          saveButtonProps={drawerSaveButton}
          deleteButtonProps={deleteButtonProps}
          recordItemId={id}
        >
          <Form {...drawerFormProps} layout="vertical">
            <Form.Item label="Megnevezés" name="azonosito1">
              <Input />
            </Form.Item>

            <Form.Item label="SAP anyagszám" name="azonosito2">
              <Input />
            </Form.Item>

            <Form.Item label="Lieferplan szám" name="azonosito3">
              <Input />
            </Form.Item>

            <Form.Item label="ECV Index	" name="elsoIndex">
              <Input />
            </Form.Item>

            <Form.Item label="EDP Index" name="masodikIndex">
              <Input />
            </Form.Item>

            <Form.Item label="Mennyiségi egység" name="mennyEgyseg">
              <Input />
            </Form.Item>
          </Form>
        </Edit>
      </Drawer>*/}
    </>
  );
};
