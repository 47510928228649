import {
  CrudFilter,
  CrudFilters,
  HttpError,
  useExport,
  usePermissions,
  useTranslate,
  useUpdateMany,
} from "@pankod/refine-core";
import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  EditButton,
  ShowButton,
  useDrawerForm,
  Drawer,
  Edit,
  Form,
  Input,
  ExportButton,
  Create,
  DeleteButton,
  useSelect,
  Select,
  Button,
  CreateButton,
  Row,
  Col,
  Icons,
  FormProps,
  getDefaultFilter,
  Card,
  BooleanField,
  Radio,
  Typography,
} from "@pankod/refine-antd";

import { ConfigProvider } from "antd";
import "moment/locale/hu";
import huHU from "antd/lib/locale/hu_HU";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import moment from "moment";
import {
  IBizonylatFej,
  IBizonylatTetelek,
  IBizonylatFilter,
} from "../../interfaces";
import { IVevok } from "../../interfaces/vevok";
import React, { useMemo, useState } from "react";
import customParseFormat from 'dayjs/plugin/customParseFormat';

export const Ellenorzes: React.FC = () => {
  const {
    tableProps: tabePropsBiz,
    sorter,
    searchFormProps,
    filters,
    tableQueryResult
  } = useTable<IBizonylatFej, HttpError, IBizonylatFilter>({
    syncWithLocation: true,
    permanentSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
    onSearch: (params) => {
      const filters: CrudFilters = [];

      const { projectId, datum, id, engedelyezve} = params;

      filters.push({
        field: "projectId",
        operator: "eq",
        value: projectId,
      });


      filters.push(
        {
          field: "datum",
          operator: "gte",
          value: datum ? datum[0].startOf("day").toISOString() : undefined,
        },
        {
          field: "datum",
          operator: "lte",
          value: datum ? datum[1].endOf("day").toISOString() : undefined,
        }
      );

      filters.push(
        {
          field: "engedelyezve",
          operator: "eq",
          value: engedelyezve
        }
      )

      return filters;
    },
  });

  const { t } = useTranslation();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const { data: permissionsData } = usePermissions();

  const { mutate, isLoading: updateManyIsLoading } = useUpdateMany<IBizonylatFej>();

  const updateSelectedItems = () => {
    mutate(
      {
        resource: "bizonylat_fejs",
        ids: selectedRowKeys.map(String),
        values: {
          engedelyezve: true,
        },
      },
      {
        onSuccess: () => {
          setSelectedRowKeys([]);
        },
      },
    );
  };

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const hasSelected = selectedRowKeys.length > 0;


  const {
    drawerProps: drawerPropsBiz,
    formProps: formPropsBiz,
    show: showBiz,
    saveButtonProps: saveButtonPropsBiz,
    deleteButtonProps: deleteButtonPropsBiz,
    id: idBiz,
  } = useDrawerForm<IBizonylatFej>({
    action: "edit",
  });

  function onChange(date: any, dateString: any) {
    console.log(date, dateString);
  }
  const [totalValue, setTotal] = useState();
  const onChangeTotalSelect = (value: any) => {
    console.log(`selected ${value}`);
    setTotal(value);
  };

  const { CloseCircleOutlined, CheckCircleOutlined } = Icons;
  const { Text, Link, Title } = Typography;
  const { Option } = Select;

  const topOptions = [
    { label: 'none', value: 'none' },
  ];

  type TablePaginationPosition =
    | 'topLeft'
    | 'topCenter'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomCenter'
    | 'bottomRight';


  //const engedelyezve = "41";  
  const [top, setTop] = useState<TablePaginationPosition>('bottomRight');

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xl={32} lg={24} xs={24}>
          <Card bordered={false} title={<h2>Szállítólevelek jóváhagyása számlázáshoz</h2>}>
            <Filter formProps={searchFormProps} filters={filters || []} />
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xl={32} xs={24}>
          <List
            headerProps={{
              subTitle: (
                <>

                  {permissionsData?.includes("superadmin") && <Button
                    type="primary"
                    onClick={updateSelectedItems}
                    disabled={!hasSelected}
                    loading={updateManyIsLoading}
                  >
                    Kijelölt elemek engedélyezése
                  </Button>}
                  <span style={{ marginLeft: 8 }}>
                    {hasSelected
                      ? `${selectedRowKeys.length} tétel kiválasztva`
                      : ""}
                  </span>

                </>
              ),
            }}
            canCreate={permissionsData?.includes("admin")}
            title="Szállítólevelek"
            pageHeaderProps={{
              extra: [
                /*permissionsData?.includes("admin") && <CreateButton></CreateButton>,
                <ExportButton onClick={triggerExport} loading={isLoading} />,*/
              ],
            }}
          >
            {/*<Space>
              <Text style={{ fontSize: 14 }}><strong>Maximum megjelenített találatok száma:</strong></Text>
              <br></br>
              <Select
                style={{ width: 100 }}
                placeholder="Maximum megjelenített találatok száma"
                onChange={onChangeTotalSelect}
                value={totalValue}
                defaultValue={10}
              >
                <Option value={100}>100</Option>
                <Option value={150}>150</Option>
                <Option value={200}>200</Option>
                <Option value={350}>350</Option>
                <Option value={500}>500</Option>
              </Select> 
            </Space>
            <br></br>
            <br></br>*/}
            <Table {...tabePropsBiz} rowSelection={rowSelection} rowKey="id" pagination={{
              ...tabePropsBiz.pagination, showSizeChanger: true, pageSizeOptions: ["10", "20"],
              showTotal: (total, range) => `Találatok: ${tableQueryResult.data?.total} (${range[0]}-${range[1]})`,
            }}>

              {/*<Table.Column
                title="Szállítólevél ID"
                dataIndex={"id"}
              />*/}

              <Table.Column
                dataIndex="engedelyezve"
                title="Jóváhagyás"
                render={(value) => (
                  <BooleanField value = {value} />
                )}
              />

              <Table.Column
                title="Szállítólevél száma"
                dataIndex={"bizonylatSzam"}
              />

              <Table.Column<IBizonylatFej>
                dataIndex="datum"
                title="Dátum"
                render={(value) => (
                  <DateField format="YYYY MMM D,  h:mm" value={value} />
                )}
              />
              {permissionsData?.includes("superadmin") && <Table.Column<IBizonylatFej>
                title="Műveletek"
                dataIndex="actions"
                render={(_text, record): React.ReactNode => {
                  return (
                    <Space>
                      <EditButton size="small" recordItemId={idBiz} onClick={() => showBiz(record.id)}>Szerkesztés</EditButton>
                      <DeleteButton size="small" recordItemId={record.id}>Törlés</DeleteButton>
                    </Space>
                  );
                }}
              />}
            </Table>
          </List>
          <Drawer {...drawerPropsBiz}>
            <Edit
              title="Szállítási adatok szerkesztése"
              saveButtonProps={saveButtonPropsBiz}
              deleteButtonProps={deleteButtonPropsBiz}
              canDelete={false}
              recordItemId={idBiz}
            >
              <Form {...formPropsBiz} wrapperCol={{ span: 14 }} layout="vertical">

                <Form.Item
                  label="Engedélyezés"
                  name="engedelyezve"
                >
                  <Radio.Group >
                    <Radio value={"1"}>Engedélyezve</Radio>
                    <Radio value={"2"}>Nincs engedélyezve</Radio>
                  </Radio.Group>
                </Form.Item>


                <Form.Item label="Bizonylatszám" name="bizonylatSzam">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item
                  label="Dátum"
                  name="datum"
                >
                  <Input style={{
                    width: "300px",
                  }} />
                </Form.Item>

                <Form.Item label="Szállító neve" name="szallitoNeve">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item label="Szállító címe" name="szallitoCime">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item label="Szállító adószáma" name="szallitoAdoszama">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item label="Szállító EU adószáma" name="szallitoEuAdoszama">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item<IVevok> label="Vevő neve" name="vevoNeve">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item<IVevok> label="Vevő címe" name="vevoCime">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item<IVevok> label="Vevő adószáma" name="vevoAdoszama">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>

                <Form.Item<IVevok> label="Vevő EU adószáma" name="vevoEuAdoszama">
                  <Input
                    style={{
                      width: "300px",
                    }}
                  />
                </Form.Item>
              </Form>
            </Edit>
          </Drawer>
        </Col>
      </Row>
    </>
  );
};

const Filter: React.FC<{ formProps: FormProps; filters: CrudFilters }> = (
  props
) => {
  dayjs.extend(customParseFormat);
  const { formProps, filters } = props;
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY/MM/DD';
  const datum = useMemo(() => {
    const start = getDefaultFilter("datum", filters, "gte");
    const end = getDefaultFilter("datum", filters, "lte");

    const startFrom = dayjs(start);
    const endAt = dayjs(end);

    if (start && end) {
      return [startFrom, endAt];
    }
    return undefined;
  }, [filters]);
  const t = useTranslate();
  return (
    <Form
      layout="vertical"
      initialValues={{
        q: getDefaultFilter("projectId", filters),
        datum,
      }}
      {...formProps}
    >
      <Row gutter={[10, 10]} align="bottom">
        <Col xl={8}>
          <Form.Item
            label="MASQ"
            name="projectId"
          >
            <Input
              allowClear
              placeholder="MASQ szerint"
              prefix={<Icons.SearchOutlined />}
            />
          </Form.Item>
        </Col>

        <Col xl={8}>
          <Form.Item
            label="Kiszállítás dátuma"
            name="datum"
          >
            <RangePicker /*defaultValue={[dayjs(), dayjs()]}*/ style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col xl={8}>
          <Form.Item
            label="Státusz szűrése"
            name="engedelyezve"
          >
            <Select
              placeholder="Státusz"
              //style={{width:"50%"}}                  
              options={[
                {
                  label: "Engedélyezett",
                  value: true,
                },
                {
                  label: "Nem engedélyezett",
                  value: false,
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[10, 40]} align="bottom">
        <Col xs={24} xl={24} md={8}>
          <Form.Item>
            <Button style={{ width: "100%" }} htmlType="submit" type="primary">
              Szűrés
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
