import { AuthProvider } from "@pankod/refine-core";

export const TOKEN_KEY = "refine-auth";

const mockUsers = [
  {
    username: "sqscsarnok",
    password: "SqS:2001",
    roles: ["admin"],
  },
  {
    username: "Judit",
    password: "SqS:2001",
    roles: ["superadmin"],
  },
  {
    username: "Adrienn",
    password: "adRienn2023",
    roles: ["editor"],
  },
  {
    username: "László",
    password: "kLaszlo2023",
    roles: ["editor"],
  },
  {
    username: "Gyöngyi",
    password: "SqS:2001",
    roles: ["editor"],
  },
];

export  const authProvider: AuthProvider = {
  login: ({ username, password, remember }) => {
    // Suppose we actually send a request to the back end here.

    const user = mockUsers.find((item) => item.username === username && item.password === password);

    if (user) {
      localStorage.setItem("auth", JSON.stringify(user));
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const parsedUser = JSON.parse(auth);
      //console.log(JSON.stringify(`JOGOSULTSÁG: ${parsedUser.roles}`))
      return Promise.resolve(parsedUser.roles);
    }
    return Promise.reject();

},
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error && error.statusCode === 401) {
      return Promise.reject();
    }

    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("auth") ? Promise.resolve() : Promise.reject(),
  getUserIdentity: () => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      const parsedUser = JSON.parse(auth);
      return Promise.resolve(parsedUser.username);
    }
    return Promise.reject();
  },
};
