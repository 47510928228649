import {
  List,
  TextField,
  TagField,
  DateField,
  Table,
  useTable,
  EditButton,
  ShowButton,
  useDrawerForm,
  Drawer,
  Edit,
  Form,
  Input,
  Create,
  DeleteButton,
  useSelect,
  Select,
  Button,
  useStepsForm,
  Steps,
  SaveButton,
  useForm,
} from "@pankod/refine-antd";
import { useNavigation, useTranslate } from "@pankod/refine-core";

import { ISzallitas } from "../../interfaces/szallitas";
import { Col, ConfigProvider, InputNumber, Row } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ITermek, IBizonylatFej } from "../../interfaces/";
import moment from "moment";

export const SzallitasCreate = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps } = useForm<IBizonylatFej>({
    redirect: "show",
  });

  const dateFormat = "YYYY/MM/DD";
  const { selectProps: selectProps1 } = useSelect<ITermek>({
    resource: "termeks",
    optionLabel: "azonosito1",
    optionValue: "id",
  });

  const { create } = useNavigation();

  const { selectProps: selectProps2 } = useSelect<ITermek>({
    resource: "termeks",
  });

  const { selectProps, queryResult, defaultValueQueryResult } =
    useSelect<ITermek>({
      resource: "termeks",
      optionLabel: "azonosito1",

      optionValue: "id",
      onSearch: (value) => [
        {
          field: "azonosito",
          operator: "eq",
          value,
        },
      ],
    });

  const allOptions = [
    ...(queryResult.data?.data || []),
    ...(defaultValueQueryResult.data?.data || []),
  ];
  const {
    formProps: createFormProps,
    drawerProps: createDrawerProps,
    show: showCreate,
    saveButtonProps: saveButtonPropsCreate,
  } = useDrawerForm<ITermek>({
    action: "create",
  });

  return (
    <>
      <Create
        title="Szállítólevél létrehozása"
        saveButtonProps={saveButtonProps}
      >
        <Form {...formProps} layout="vertical">
          <Row gutter={20}>
            <Col xs={24} lg={16}>
              <Row gutter={65}>
                <Col xs={24} lg={12}>
                  <Row gutter={20}>
                    <Col xl={12} lg={24}>
                      <Form.Item
                        label="Dátum"
                        name="datum"
                        initialValue={moment()}
                        getValueProps={(value) => ({
                          value: value ? moment(value) : moment(),
                        })}
                      >
                        <DatePicker />
                      </Form.Item>
                    </Col>
                    <Col xl={12} lg={24}>
                      <Form.Item
                        label="MASQ"
                        name="projectId"
                        rules={[
                          {
                            required: true,
                            message: t("defaultValidateMessages.required", {
                              name: "${label}",
                            }),
                          },
                        ]}
                      >
                        <InputNumber addonBefore="MASQ" placeholder="MASQ száma" controls={false}/>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={20}>
                    <Col xs={24}>
                      <Form.Item label="Szállító neve" name="szallitoNeve">
                        <Input placeholder="SQS 2001 KFT" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Szállító címe" name="szallitoCime">
                        <Input placeholder="H-9700 Szombathely, Sárvár u. 1" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="Szállító adószáma"
                        name="szallitoAdoszama"
                      >
                        <Input placeholder="12620667-2-18" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label="Szállító EU adószáma"
                        name="szallitoEuAdoszama"
                      >
                        <Input placeholder="HU12620667" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col xs={24} lg={12}>
                  <Row gutter={20}>
                    <Col xs={24}>
                      <Form.Item label="Vevő neve" name="vevoNeve">
                        <Input placeholder="Schaeffler Savaria KFT." />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Vevő címe" name="vevoCime">
                        <Input placeholder="9700 Szombathely, Zanati u. 31." />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Vevő adószáma" name="vevoAdoszama">
                        <Input placeholder="11315315-2-44" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Vevő EU adószáma" name="vevoEuAdoszama">
                        <Input placeholder="HU11315315" />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item label="Járatszám" name="jaratSzam">
                        <Input placeholder="LTL-720" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={20} xs={24} lg={30}>
              <Form.List name="tetelek">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field) => (
                      <Space
                        key={field.key}
                        style={{
                          display: "grid",
                          marginBottom: 8,
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...field}
                          name={[field.name, "id"]}
                          fieldKey={[field.key, "id"]}
                          hidden
                        >
                          <Input />
                        </Form.Item>

                        <Row gutter={20}>
                          <Col xs={24} lg={16}>
                            <Row gutter={20}>
                              <Col xs={24} lg={18}>
                                <Form.Item
                                  label="Termékek besorolási száma"
                                  name={[field.name, "termek", "id"]}
                                >
                                  <Select
                                    style={{ width: "120%" }}
                                    {...selectProps}
                                    options={allOptions.map((p) => ({
                                      label: `${p.azonosito1}     ---     ${p.azonosito2}   ---    ${p.azonosito3}   ---    ${p.elsoIndex}   ---    ${p.masodikIndex}`,
                                      value: p.id,
                                    }))}
                                    onChange={(value) => {
                                      formProps.form?.setFieldsValue({
                                        termek: { id: 1 },
                                      });
                                    }}
                                  />
                                </Form.Item>
                              </Col>
                              <Col xs={24} lg={8}>
                                <Form.Item
                                  label="Mennyisége"
                                  name={[field.name, "mennyisege"]}
                                >
                                  <InputNumber
                                    style={{ width: "80%" }}
                                    formatter={(value) =>
                                      `${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )
                                    }
                                    controls={false}
                                    min={1}
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                        </Row>

                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                        />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Új hozzáadása
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Col>
          </Row>
          {/*<pre>{JSON.stringify(formProps.form?.getFieldsValue(), null, 2)}</pre>*/}
        </Form>
      </Create>
    </>
  );
};
